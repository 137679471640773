<template>
    <div v-loading="loading" class="chart">
        <v-chart ref="chart" class="chart" :option="option" />
    </div>
</template>

<script>
export default {
    name: 'calendarChart',
    data() {
        return {
            loading: false,
            option: {
                title: {
                    top: 0,
                    left: 'center',
                    text: '全年体检人数分布热力图'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        return `<div>${params.value[0]}</div>
                                <div>人数: ${params.value[1]}</div>`
                    },
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                visualMap: {
                    min: 0,
                    max: 2000,
                    calculable: true,
                    orient: 'vertical',
                    show: false
                },
                calendar: [
                    {
                        left: '5%',/** 图表距离画布左侧的间隔 (自适应必设置)*/
                        right: '1%',/** 图表距离画布右侧的间隔 (自适应必设置)*/
                        // top: '20',/** 距离顶部的间隔 */
                        range: '2024',
                        cellSize: ['auto', 20]
                    },
                    {
                        left: '5%',/** 图表距离画布左侧的间隔 (自适应必设置)*/
                        right: '1%',/** 图表距离画布右侧的间隔 (自适应必设置)*/
                        top: 260,
                        range: '2023',
                        cellSize: ['auto', 20]
                    },
                    {
                        top: 450,
                        left: '5%',/** 图表距离画布左侧的间隔 (自适应必设置)*/
                        right: '1%',/** 图表距离画布右侧的间隔 (自适应必设置)*/
                        range: '2022',
                        cellSize: ['auto', 20]
                    }
                ],
                series: [
                    {
                        type: 'heatmap',
                        coordinateSystem: 'calendar',
                        calendarIndex: 0,
                        data: []
                    },
                    {
                        type: 'heatmap',
                        coordinateSystem: 'calendar',
                        calendarIndex: 1,
                        data: []
                    },
                    {
                        type: 'heatmap',
                        coordinateSystem: 'calendar',
                        calendarIndex: 2,
                        data: []
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/statistics/calendar_chart')
            this.option.series[0].data = resp.data
            this.option.series[1].data = resp.data
            this.option.series[2].data = resp.data
            this.$refs.chart.setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.chart.resize()
        })
        window.addEventListener('resize', () => {
            if (this.$refs && this.$refs.chart) {
                this.$refs.chart.resize()
            }
        })
        this.getData()
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 600px;
}
</style>
